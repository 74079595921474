import React, { useState, useContext, useEffect } from "react";

import { ActivityContext } from "../pages/Activity/Activity";
import useStyles from "../utils/styles.js";

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemAvatar,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { differenceInCalendarDays } from "date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { resizeImage } from "../utils/api";

const SingleTask = (props) => {
  const { handleUpdateWatering } = useContext(ActivityContext);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [avatarImageUrl, setAvatarImageUrl] = useState("");
  const [dialogImageUrl, setDialogImageUrl] = useState("");

  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(today);
  const deltaDays = differenceInCalendarDays(
    today,
    new Date(`${props.lastWatered}T00:00:00`)
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onWateringClick = () => {
    handleUpdateWatering(
      props.plantName,
      props.waterDate,
      selectedDate,
      props.lastWatered
    );
    setOpen(false);
  };

  let color = "grey";
  let text = `${props.plantName} (${props.plantType})`;

  if (props.watered === "true") {
    color = "green";
    text = `${props.plantName} (${props.plantType}), ${props.wateredOn}`;
  }

  useEffect(() => {
    const getImage = async () => {
      setAvatarImageUrl(
        resizeImage(`public/${props.imagePath}`, 400, 400, true)
      );
      setDialogImageUrl(
        resizeImage(`public/${props.imagePath}`, 400, 550, false)
      );
    };
    getImage();
  }, [props.id]);

  return (
    <>
      <ListItem button className={classes.nested} onClick={handleClickOpen}>
        <ListItemAvatar>
          <Avatar alt={props.plantName} src={avatarImageUrl} />
        </ListItemAvatar>
        <ListItemText primary={text} style={{ color: color }} />
      </ListItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {props.plantName} - {props.plantType}
        </DialogTitle>
        <DialogContent>
          <img src={dialogImageUrl} alt={props.plantName} />
          <DialogContentText>
            Last time you watered <b>{props.plantName}</b> was{" "}
            <b>{props.lastWatered}</b> ({deltaDays} days ago from today). On
            average, you give water every{" "}
            <b>{props.wateringIntervalAvgDays} days</b>
          </DialogContentText>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline"
              label="Which day you gave water ?"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onWateringClick} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SingleTask;
