import React from "react";
import uuid from "react-uuid";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import SingleTask from "../components/SingleTask.js";

import { isBefore, isAfter } from "date-fns";

const shouldBeCollapsed = (activity = []) =>
  activity.every((k) => k.watered === "true");

// Activity for a given day, list of single tasks
const DayActivity = ({ data = [], today, todayIso }) => {
  const [open, setOpen] = React.useState(!shouldBeCollapsed(data.activity));
  const handleClick = () => {
    setOpen(!open);
  };
  var text = {};

  const d = new Date(`${data.waterDate}T00:00:00`);

  if (data.waterDate === todayIso) {
    text = {
      color: "red",
    };
  } else if (isBefore(d, today)) {
    text = {
      color: "grey",
    };
  } else if (isAfter(d, today)) {
    text = {
      color: "black",
    };
  }

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <CalendarTodayIcon />
        </ListItemIcon>
        <ListItemText
          primary={data.waterDate}
          primaryTypographyProps={{ variant: "h6", style: text }}
        />
        {open ? <ExpandMore /> : <ExpandLess />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {data.activity.map((a) => (
            <SingleTask key={uuid()} waterDate={data.waterDate} {...a} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default DayActivity;
