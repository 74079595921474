import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import uuid from "react-uuid";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { getCatalog } from "../../utils/api";

import AddNewPlantDialog from "../../components/AddNewPlantDialog";
import PlantDisplay from "../../components/PlantDisplay/PlantDisplay";

import { styles } from "./CatalogStyles";

const override = css`
  display: block;
  margin: 0 auto;
  color: "white";
`;

const Catalog = (props) => {
  const { classes } = props;

  const [catalog, setCatalog] = useState([]);
  const [id] = useState(uuid());
  const [loading, setLoading] = useState(true);
  const [color] = useState("#14CCD3");
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    "Start typing to filter"
  );
  const [showAddPlantDialog, setShowAddPlantDialog] = useState(false);

  const handleOnClose = () => {
    setShowAddPlantDialog(false);
  };

  const fetchCatalog = async () => {
    setLoading(true);
    const response = await getCatalog();
    console.log(response);
    setCatalog(response);
    setLoading(false);
    setSearchPlaceholder(`${response.length} plants - type here to filter`);
  };

  useEffect(() => {
    fetchCatalog();
  }, [id]);

  return (
    <>
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <SearchIcon className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder={searchPlaceholder}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addUser}
                  onClick={() => setShowAddPlantDialog(true)}
                >
                  Add new plant
                </Button>
                <Tooltip title="Reload">
                  <IconButton onClick={() => fetchCatalog()}>
                    <RefreshIcon className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={30}
          />
          {/* <List
            component="nav"
            aria-labelledby="nested-list-subheader"
          > */}
          {catalog.map((d, i) => (
            <PlantDisplay key={i} plant={d} classes={classes} />
          ))}
          {/* </List> */}
        </div>
      </Paper>
      <AddNewPlantDialog open={showAddPlantDialog} onClose={handleOnClose} />
    </>
  );
};

export default withStyles(styles)(Catalog);
