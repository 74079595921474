import React, { useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Navigator from "../../components/Navigator";
import Header from "../../components/Header";

import { createBrowserHistory } from "history";
import Catalog from "../Catalog/Catalog";
import Activity from "../Activity/Activity";
import ActivityLegacy from "../ActivityLegacy";

import { theme } from "../../utils/Theme.js";
import { styles } from "./HomeStyles.js";

import { Router, Switch, Route } from "react-router-dom";
import PlantDetails from "../PlantDetails/PlantDetails";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      Happy Jungle House
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const history = createBrowserHistory();

const drawerWidth = 256;

function Paperbase(props) {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>

          <div className={classes.app}>
            <Switch>
              <Route exact path="/">
                <Header onDrawerToggle={handleDrawerToggle} title="Activity" />
                <main className={classes.main}>
                  <Activity />
                </main>
              </Route>
            </Switch>
            <Switch>
              <Route exact path="/legacy">
                <Header onDrawerToggle={handleDrawerToggle} title="History" />
                <main className={classes.main}>
                  <ActivityLegacy />
                </main>
              </Route>
            </Switch>
            <Switch>
              <Route exact path="/catalog">
                <Header onDrawerToggle={handleDrawerToggle} title="Catalog" />
                <main className={classes.main}>
                  <Catalog />
                </main>
              </Route>
            </Switch>
            <Switch>
              <Route exact path="/plants/:plantName">
                <main className={classes.main}>
                  <PlantDetails />
                </main>
              </Route>
            </Switch>
            <footer className={classes.footer}>
              <Copyright />
            </footer>
          </div>
        </div>
      </ThemeProvider>
    </Router>
  );
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase);
