import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import HelpIcon from "@material-ui/icons/Help";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Tooltip,
  Typography,
  Toolbar,
  IconButton,
  Grid,
  AppBar,
  Hidden,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Amplify from "aws-amplify";

import { signOut } from "../utils/api";
import AlertDialog from "./AlertDialog/AlertDialog";

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: "none",
    color: lightColor,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

function Header(props) {
  const { classes, onDrawerToggle } = props;
  const [username, setUsername] = useState("");
  const [openDisconnect, setOpenDisconnect] = useState(false);

  useEffect(() => {
    const getUsername = async () => {
      const { attributes } = await Amplify.Auth.currentAuthenticatedUser();
      console.log(attributes);
      setUsername(attributes.email);
    };
    getUsername();
    console.log(username);
  }, [props.id]);

  const onClickDisconnect = async () => {
    await signOut();
    window.location.reload();
    setOpenDisconnect(false);
  };

  return (
    <>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <Typography>Howdy, {username}!</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Disconnect">
                <IconButton
                  color="inherit"
                  onClick={() => {
                    setOpenDisconnect(true);
                  }}
                >
                  <PowerSettingsNewIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {props.title}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AlertDialog
        open={openDisconnect}
        title="Confirmation"
        content="Do you want to close your session?"
        confirmAction={onClickDisconnect}
        cancelAction={() => {
          setOpenDisconnect(false);
        }}
      />
    </>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);
