import React from "react";
import ReactDOM from "react-dom";
import App from './App.js';

import Amplify, { API } from 'aws-amplify';
import awsExports from "./aws-exports";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "mjhapi",
        endpoint: "https://ei82m3yoxg.execute-api.eu-west-1.amazonaws.com"
      }
    ]
  },
  ...awsExports
})
API.configure();

ReactDOM.render(<App />, document.querySelector('#root'));

