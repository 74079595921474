/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:6ce6832b-8f9b-4e07-b7a1-48fe7520b8de",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_XVTNZopvu",
    "aws_user_pools_web_client_id": "6c9661f451bfstc0m0luo9uora",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "mjhreact-20210825122304-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d2m0f4jppnz888.cloudfront.net",
    "aws_user_files_s3_bucket": "mjhreact4c1168269852467e8051f8c1fde6b3bb225554-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
