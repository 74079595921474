import Amplify from "aws-amplify";
import { differenceInCalendarDays } from "date-fns";

const apiName = "mjhapi";
const imageResizeEndpoint = "https://d2cbsyoud4t9fg.cloudfront.net";
const assetsBuckets = "mjhreact4c1168269852467e8051f8c1fde6b3bb225554-dev";

const amplifyHeaders = async () => ({
  Authorization: `Bearer ${(await Amplify.Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`,
});

export async function signOut() {
  try {
    await Amplify.Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

export async function updateWatering(
  plantName,
  waterDate,
  wateredOn,
  lastWatered
) {
  const path = `/dev/water/${plantName}`;
  const deltaDays = differenceInCalendarDays(
    new Date(`${wateredOn}T00:00:00`),
    new Date(`${lastWatered}T00:00:00`)
  );
  const headers = await amplifyHeaders();
  const myInit = {
    headers,
    body: {
      waterDate: waterDate,
      wateredOn: wateredOn,
      deltaDays: deltaDays,
    },
  };
  return await Amplify.API.put(apiName, path, myInit);
}

export async function getCatalog() {
  const path = `/dev/catalog`;
  const headers = await amplifyHeaders();
  const myInit = { headers };
  return await Amplify.API.get(apiName, path, myInit);
}

export async function getData(from, to, focused) {
  const path = `/dev/activity/${from}/${to}?focused=${focused}`;
  const headers = await amplifyHeaders();
  const myInit = { headers };
  return await Amplify.API.get(apiName, path, myInit);
}

export async function addNewPlant(payload) {
  const path = `/dev/plants`;
  const headers = await amplifyHeaders();
  const myInit = {
    headers,
    body: payload,
  };
  return await Amplify.API.post(apiName, path, myInit);
}

export async function editPlant(payload) {
  const path = `/dev/plants/${payload.plantName}`;
  const headers = await amplifyHeaders();
  const myInit = {
    headers,
    body: payload,
  };
  return await Amplify.API.put(apiName, path, myInit);
}

export function resizeImage(src, width, height, round = false) {
  const imageRequest = JSON.stringify({
    bucket: assetsBuckets,
    key: src,
    edits: {
      resize: {
        width: width,
        height: height,
        fit: "cover",
      },
      smartCrop: true,
      roundCrop: round,
    },
  });
  return `${imageResizeEndpoint}/${btoa(imageRequest)}`;
}
