import React, { useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import uuid from "react-uuid";

import DayActivity from "../../components/DayActivity.js";
import { formatISO, format, addDays } from "date-fns";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

import { styles } from "./ActivityStyles";

import { getData, updateWatering } from "../../utils/api";

const override = css`
  display: block;
  margin: 0 auto;
  color: "white";
`;

export const ActivityContext = createContext();

function Activity(props) {
  const { classes } = props;

  const [data, setData] = useState([]);
  const [id] = useState(uuid());

  const [loading, setLoading] = useState(true);
  const [color] = useState("#14CCD3");

  const today = new Date();

  const todayIso = formatISO(today, { representation: "date" });

  //missed past activities and next week upcoming
  const from = format(addDays(today, -15), "yyyy-MM-dd");
  const to = format(addDays(today, 7), "yyyy-MM-dd");

  const fetchActivities = async () => {
    setLoading(true);
    const focused = true; // shows only not watered activities for the period
    const response = await getData(from, to, focused);
    console.log(response);
    setData(response);
    setLoading(false);
  };

  const handleUpdateWatering = async (
    plantName,
    waterDate,
    selectedDate,
    lastWatered
  ) => {
    setLoading(true);
    const response = await updateWatering(
      plantName,
      waterDate,
      format(selectedDate, "yyyy-MM-dd"),
      lastWatered
    );
    fetchActivities();
    console.log(response);
  };

  useEffect(() => {
    fetchActivities();
  }, [id]);

  return (
    <ActivityContext.Provider
      value={{ data, setData, fetchActivities, handleUpdateWatering }}
    >
      <Paper className={classes.paper}>
        <div className={classes.contentWrapper}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Upcoming week and past due activities.
              </ListSubheader>
            }
          >
            <ClipLoader
              color={color}
              loading={loading}
              css={override}
              size={30}
            />
            {data.map((d, i) => (
              <DayActivity key={i} data={d} today={today} todayIso={todayIso} />
            ))}
          </List>
        </div>
      </Paper>
    </ActivityContext.Provider>
  );
}

Activity.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Activity);
