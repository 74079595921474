const plantDisplayStyle = {
  root: {
    display: "flex",
    flexDirection: "column",
    minWidth: "400px",
    maxWidth: "400px",
    margin: "10px auto 50px",
    justifyContent: "space-between",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
};

export { plantDisplayStyle as style };
