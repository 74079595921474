import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Amplify from "aws-amplify";


import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import uuid from 'react-uuid'

import DayActivity from "../components/DayActivity.js"
import { formatISO, format, startOfMonth, endOfMonth } from 'date-fns'

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

import { getData } from "../utils/api";


const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

const override = css`
  display: block;
  margin: 0 auto;
  color: "white";
`;

function ActivityLegacy(props) {
  const { classes } = props;

  const [data, setData] = useState([]);
  const [id] = useState(uuid());

  const [loading, setLoading] = useState(true);
  const [color] = useState("#14CCD3");

  const today = new Date()

  const todayIso = formatISO(today, { representation: 'date' })
  const from = format(startOfMonth(today), "yyyy-MM-dd")
  const to = format(endOfMonth(today), "yyyy-MM-dd")

  const fetchActivities = async () => {
  
      const focused = false // shows all activities (watered/ not watered) for the period
      const response = await getData(from, to, focused);
      console.log(response)
      setData(response)
      setLoading(!loading)
  }

  useEffect(() => {
    fetchActivities();
  }, [id])

  return (
    <Paper className={classes.paper}>     
      <div className={classes.contentWrapper}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Monthy activity view 
            </ListSubheader>
          }
        >
          <ClipLoader color={color} loading={loading} css={override} size={30} />
          {data.map((d, i) => (
            <DayActivity key={i} data={d} today={today} todayIso={todayIso} />
          ))}
        </List>
      </div>
    </Paper>
  );
}

ActivityLegacy.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActivityLegacy);
