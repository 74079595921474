import React from "react";

import { withAuthenticator } from "@aws-amplify/ui-react";
import Home from "./pages/Home/Home.js";

// high level app layout
const App = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default withAuthenticator(App);
