import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  NativeSelect,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import ClipLoader from "react-spinners/ClipLoader";

import Amplify from "aws-amplify";
import { add, differenceInDays } from "date-fns";

import { addNewPlant } from "../utils/api";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const AddNewPlantDialog = (props) => {
  const classes = useStyles();

  const [uploading, setUploading] = useState(false);
  const [color] = useState("#14CCD3");

  const today = new Date();
  const [selectedKind, setSelectedKind] = useState("");
  const [lastWatered, setLastWatered] = useState(today);
  const [nextWaterDate, setNextWaterDate] = useState(today);
  const [imagePath, setImagePath] = useState("");
  const plantNameRef = useRef("");
  const plantTypeRef = useRef("");

  const handleLastWateredDateChange = (date) => {
    setLastWatered(date);
  };
  const handleNextWateredDateChange = (date) => {
    setNextWaterDate(date);
  };
  const handlePlantKindChange = (event) => {
    setSelectedKind(event.target.value);
  };

  const handleAddPlant = async () => {
    const delta = differenceInDays(nextWaterDate, lastWatered);
    const payload = {
      plantName: plantNameRef.current.value,
      lastWatered: format(lastWatered, "yyyy-MM-dd"),
      nextWaterDate: format(nextWaterDate, "yyyy-MM-dd"),
      plantKind: selectedKind,
      plantType: plantTypeRef.current.value,
      imagePath: imagePath,
      wateringIntervalMaxDays: delta,
      wateringIntervalMinDays: delta,
      wateringIntervalAvgDays: delta,
    };
    try {
      const response = await addNewPlant(payload);
      console.log(response);
    } catch (error) {
      console.error("Error adding plant: ", error);
    }
    props.onClose();
  };

  async function onImageChange(e) {
    const file = e.target.files[0];
    setUploading(true);
    try {
      let user = await Amplify.Auth.currentAuthenticatedUser();
      const type = file.type.split("/")[1];
      const filename = `${user.username}/tmp/${Date.now()}.${type}`.replaceAll(
        " ",
        "-"
      );
      setImagePath(filename);
      const result = await Amplify.Storage.put(filename, file, {
        expires: add(new Date(), { minutes: 15 }),
        contentType: file.type,
      });
      setImagePath(result.key);
      console.log(result);
    } catch (error) {
      console.log("Error uploading file: ", error);
    } finally {
      setUploading(false);
    }
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add a new plant</DialogTitle>
        <DialogContent>
          <form className={classes.root} noValidate autoComplete="off">
            <DialogContentText>
              Pick a beautiful image that represent your new plant
            </DialogContentText>
            <ClipLoader color={color} loading={uploading} size={30} />
            {!uploading ? (
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                id="image"
                onChange={onImageChange}
              />
            ) : (
              <></>
            )}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nickname: Unleash your imagination"
              type="text"
              inputRef={plantNameRef}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="type"
              label="Type (ex: Monstera Deliciosa)"
              type="text"
              inputRef={plantTypeRef}
              fullWidth
            />
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="kind-native-helper">Kind</InputLabel>
              <NativeSelect
                value={selectedKind}
                onChange={handlePlantKindChange}
                name="kind"
                className={classes.selectEmpty}
                fullWidth
                inputProps={{
                  name: "kind",
                  id: "kind-native-helper",
                }}
              >
                <option aria-label="None" value="" />
                <option value={"Tropical"}>Tropical</option>
                <option value={"Succulent"}>Succulent</option>
                <option value={"Cactus"}>Cactus</option>
              </NativeSelect>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                id="lastWatered"
                label="Last watering date"
                value={lastWatered}
                onChange={handleLastWateredDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                id="nextWaterDate"
                label="Next watering date"
                value={nextWaterDate}
                onChange={handleNextWateredDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddPlant} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewPlantDialog;
