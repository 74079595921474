import { theme } from "../../utils/Theme.js";

const drawerWidth = 256;

const activityStyles = {
  paper: {
    maxWidth: 1000,
    margin: "auto",
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
};

export { activityStyles as styles };
