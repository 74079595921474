import React, { useState, useEffect, createContext } from "react";
import { withStyles } from "@material-ui/core/styles";

import { styles } from "./PlantDetailsStyles.js";

import { useParams } from "react-router-dom";

function PlantDetails(props) {
  let { plantName } = useParams();
  return (
    <div>
      <h3>{plantName}</h3>
    </div>
  );
}

export default withStyles(styles)(PlantDetails);
