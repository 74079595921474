import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { NativeSelect } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ClipLoader from "react-spinners/ClipLoader";

import Amplify from "aws-amplify";
import { add } from "date-fns";

import { editPlant } from "../utils/api";

import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const EditPlantDialog = (props) => {
  const classes = useStyles();
  const { data } = props;

  console.log(data);

  const [uploading, setUploading] = useState(false);
  const [color] = useState("#14CCD3");

  const today = new Date();
  const [selectedKind, setSelectedKind] = useState(data.plantKind);
  const [imagePath, setImagePath] = useState(data.imagePath);

  const plantTypeRef = useRef(data.plantType);

  const handlePlantKindChange = (event) => {
    setSelectedKind(event.target.value);
  };

  const handleEditPlant = async () => {
    const payload = {
      plantName: data.plantName,
      plantKind: selectedKind,
      plantType: plantTypeRef.current.value,
      imagePath: imagePath,
    };
    console.log(payload);
    try {
      const response = await editPlant(payload);
      console.log(response);
    } catch (error) {
      console.error("Error adding plant: ", error);
    }

    props.onClose();
  };

  async function onImageChange(e) {
    const file = e.target.files[0];
    setUploading(true);
    try {
      let user = await Amplify.Auth.currentAuthenticatedUser();
      const type = file.type.split("/")[1];
      const filename = `${user.username}/tmp/${Date.now()}.${type}`.replaceAll(
        " ",
        "-"
      );
      setImagePath(filename);
      const result = await Amplify.Storage.put(filename, file, {
        expires: add(new Date(), { minutes: 15 }),
        contentType: file.type,
      });
      setImagePath(result.key);
      console.log(result);
    } catch (error) {
      console.log("Error uploading file: ", error);
    } finally {
      setUploading(false);
    }
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Edit - {data.plantName}
        </DialogTitle>
        <DialogContent>
          <form className={classes.root} noValidate autoComplete="off">
            <DialogContentText>
              Modify {data.plantName}'s attributes or image
            </DialogContentText>
            <ClipLoader color={color} loading={uploading} size={30} />
            {!uploading ? (
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                id="image"
                onChange={onImageChange}
              />
            ) : (
              <></>
            )}
            <TextField
              margin="dense"
              id="type"
              label="Type (ex: Monstera Deliciosa)"
              type="text"
              defaultValue={data.plantType}
              inputRef={plantTypeRef}
              fullWidth
              required
            />
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="kind-native-helper">Kind</InputLabel>
              <NativeSelect
                value={selectedKind}
                onChange={handlePlantKindChange}
                name="kind"
                className={classes.selectEmpty}
                inputProps={{
                  name: "kind",
                  id: "kind-native-helper",
                }}
              >
                <option aria-label="None" value="" />
                <option value={"Tropical"}>Tropical</option>
                <option value={"Succulent"}>Succulent</option>
                <option value={"Cactus"}>Cactus</option>
              </NativeSelect>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditPlant} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditPlantDialog;
