import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";

import EditPlantDialog from "../EditPlantDialog";
import { style } from "./PlantDisplayStyle";
import { resizeImage } from "../../utils/api";

const useStyles = makeStyles(() => style);

const PlantDisplay = (props) => {
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState("");
  const [showEditDialog, setShowEditDialog] = useState(false);

  let history = useHistory();

  const onClickRedirect = () => {
    history.push(`/plants/${props.plant.plantName}`);
  };

  useEffect(() => {
    const getImage = async () => {
      setImageUrl(resizeImage(`public/${props.plant.imagePath}`, 800, 800));
    };
    getImage();
  }, [props.id]);

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title={props.plant.plantName}
          subheader={props.plant.plantType}
        />
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={imageUrl}
            title={props.plant.plantName}
            onClick={onClickRedirect}
          />
          <CardContent>
            {/* <Typography gutterBottom variant="h5" component="h3">
              {props.plant.plantName}
            </Typography> */}
            {/* <Typography variant="body2" color="textSecondary" component="p">
              {`  Watered ${props.plant.wateringCount} times since ${props.plant.createdAt}.`}
              {`On average ${props.plant.plantName} is watered every ${props.plant.wateringIntervalAvgDays} days, `}
              {`with a minimum of ${props.plant.wateringIntervalMinDays} and a maximum of ${props.plant.wateringIntervalMaxDays} days in between waterings.`}
              </Typography> */}
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              setShowEditDialog(true);
            }}
          >
            <EditIcon />
          </Button>
        </CardActions>
      </Card>
      <EditPlantDialog
        open={showEditDialog}
        onClose={() => {
          setShowEditDialog(false);
        }}
        mode="edit"
        data={props.plant}
      />
    </>
  );
};

export default PlantDisplay;
